import queryString from 'query-string'
import I18n from '../helpers/I18n'
import { currencyCb, currencyString } from '../components/common/Currency'

/**
 * Trim a string by keeping a certain amount of characters,
 * while preventing words from being cut off.
 *
 * @param string the string to trim
 * @param trimLength the amount of characters to keep
 */
export function trimString(
  string: string,
  trimLength: number,
  concatContent?: string
): { trimmedString: string; lastIndex: number } {
  if (trimLength >= string.length) {
    return { trimmedString: string, lastIndex: string.length - 1 }
  }
  let trimmedString = string.substr(0, trimLength)
  const lastIndex = trimmedString.lastIndexOf(' ')
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, lastIndex))
  trimmedString =
    concatContent && trimmedString.length < string.length
      ? trimmedString.concat(concatContent)
      : trimmedString

  return { trimmedString, lastIndex }
}

export function numberWithCommas(num: number | string): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Converts string to be in a vanity URL format, i.e., lowercase with all spaces replaced by hyphens
 * @param str the url string to format
 */
export function toVanityUrl(str: string): string {
  return str
    .toLowerCase()
    .trim()
    .replace(/[\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g, '-')
}

/**
 *
 * @param str the string to test for emptiness
 */
export function isEmpty(str: string | undefined): boolean {
  return !str || 0 === str.trim().length
}

//
/**
 * Strips HTTP:// and HTTPS:// from urls
 * @param url a url to strip the schema from
 */
export function stripHttp(url: string): string {
  const regex = /^https?:\/\//i
  if (regex.test(url)) {
    url = url.replace(regex, '')
  }
  return url
}

/**
 * returns only the domain component of an email address
 * @param email an email address
 */
export function extractDomainFromEmail(email: string): string {
  return email.replace(/.*@/, '')
}

export const EmailPattern = /^([\w-+\.]+@([\w-]+\.)+[\w-]{2,10})?$/
/**
 *
 * @param email String to determine if conforms to email regex
 */
export function validateEmail(email: string): boolean {
  return EmailPattern.test(email)
}
/**
 * Normalizes a string according to the 'NFD' unicode standard
 * @param inputString
 */
export function normalizeString(inputString: string): string {
  return inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Capitalizes the first letter of a given string
 * @param string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 *
 * @param pStr
 * @param pPrecision
 */
export function asSafeFloat(pStr: string, pPrecision: number): string {
  let n = parseFloat(pStr)
  if (isNaN(n)) {
    n = 0
  }
  return n.toFixed(pPrecision)
}

/**
 * Replace underscores with spaces and capitalize words
 * @param str
 */
export function humanize(str: string): string {
  const frags = str.split('_')
  for (let i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].length == 2 && i == 0 // Capitalize first word when 2 characters long (e.g. 'io_expander' => 'IO Expander')
        ? frags[i].toUpperCase()
        : frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(' ')
}

/**
 * Change case to kebab-case
 * @param str
 */
export function toKebabCase(str: string): string {
  return str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase())
}

/**
 * Return string for using as CSS variable name
 * @param str
 * @example
 * // returns --padding-top
 * toCssVariableName('paddingTop')
 */
export function toCssVariableName(str: string): string {
  return `--${toKebabCase(str)}`
}

/**
 * removes all whitespace from a string
 * @param string
 */
export function removeWhitespace(string: string): string {
  return string.replace(/\s/g, '')
}

/**
 * Returns comma separated price with dollar sign and USD
 * @param price
 */
export function displayFormattedPrice(price: string): string {
  price = parseFloat(price).toFixed(2)
  return `$${numberWithCommas(price)} USD`
}

/**
 * Appends query string
 * @param base
 * @param querySettings
 */
export function appendQueryString(base: string, querySettings: Record<string, any>): string {
  const query =
    Object.entries(querySettings).length !== 0
      ? `?${queryString.stringify(querySettings, { arrayFormat: 'bracket' })}`
      : ''
  const path = base.concat(query)
  return path
}

/**
 * Used to transform any primitive value to a string.
 * Mainly used to convert a value for an input to a string
 * so that the input remains a controlled react component.
 *
 * `null` | `undefined` => `''`
 *
 * @param value
 */
export function stringifyInput(value: string | number | boolean | null | undefined): string {
  if (value === undefined || value === null || typeof value === 'boolean') {
    return ''
  } else if (typeof value === 'number') {
    return value.toString()
  } else {
    return value
  }
}

/**
 * Used to transform a number into an ordinal.
 * Example outputs include '1st', '2nd', '3rd', '4th'.
 *
 * @param number
 */
export function ordinal(number) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (s[(v - 20) % 10] || s[v] || s[0])
}

/**
 * Used to validate the first two characters of a string as letters.
 *
 * @param string
 */
export function firstTwoCharactersAreLetters(string) {
  const lettersOnlyRegExp = /^[A-Za-z]*$/
  const firstTwoCharacters = string.substring(0, 2)

  return lettersOnlyRegExp.test(firstTwoCharacters)
}

/**
 * Used to format a US tax ID as 9 numbers only with a dash.
 * (XX-XXXXXXX)
 *
 * @param string
 */
export function formatUsTaxId(string) {
  const numbersOnlyRegExp = /^\d*$/
  const firstTwoCharacters = string.substring(0, 2)
  const thirdCharacter = string.substring(2, 3)
  const lastSevenCharacters = string.substring(3, 10)

  if (numbersOnlyRegExp.test(string)) {
    if (string.length === 3) {
      const excludingLastCharacter = string.substring(0, string.length - 1)
      const lastCharacter = string.substring(string.length - 1)

      string = `${excludingLastCharacter}-${lastCharacter}`
    }

    return string
  } else if (
    numbersOnlyRegExp.test(firstTwoCharacters) &&
    thirdCharacter === '-' &&
    numbersOnlyRegExp.test(lastSevenCharacters)
  ) {
    const firstTenCharacters = string.substring(0, 10)

    return firstTenCharacters
  } else {
    const excludingLastCharacter = string.substring(0, string.length - 1)

    return excludingLastCharacter
  }
}

export const joinWithSpaceAndTrim = (...args: Array<string | undefined>) => args.join(' ').trim()

/**
 * Used to transform a number to alphabet.
 *
 * @param number
 */
export function numToAlphabet(number) {
  let s = '',
    t

  while (number > 0) {
    t = (number - 1) % 26
    s = String.fromCharCode(65 + t) + s
    number = ((number - t) / 26) | 0
  }
  return s || undefined
}

/**
 * Used to transform a month count into string. Eg. 13 = '1 Year 1 Month'
 *
 * @param number
 */
export function numToyearsAndMonths(monthCount) {
  function getPlural(number, word) {
    return (number === 1 && word.one) || word.other
  }

  const months = { one: I18n.t('datetime.units.month'), other: I18n.t('datetime.units.months') },
    years = { one: I18n.t('datetime.units.year'), other: I18n.t('datetime.units.years') },
    m = monthCount % 12,
    y = Math.floor(monthCount / 12),
    result = [] as Array<string>

  y && result.push(`${y} ${getPlural(y, years)}`)
  m && result.push(`${m} ${getPlural(m, months)}`)
  return result.join(' ')
}

export const pluralize = (count, noun, plural = `${noun}s`) =>
  `${count} ${count === 1 ? noun : plural}`

/**
 * Used to transform a camel case string into snake case.
 * Example 'sampleCase' => 'sample_case'.
 *
 * @param string
 */
export function camelToSnakeCase(string) {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

/**
 * Used to transform a string into kebab case.
 * Example 'sampleCase' => 'sample-case'.
 *
 * @param string
 */
export function stringToKebabCase(string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}

/**
 * Used to replace price values within a string to the set currency.
 *
 * @param string
 * @param currencyExchangeRate
 */
export function searchAndConvertPricesToCurrency(string, currencyExchangeRate, precision = 0) {
  return string.replace(/\$\d+(\.\d{2})?/g, match => {
    const price = parseFloat(match.replace('$', ''))
    return currencyString(
      currencyCb(price * currencyExchangeRate.rate, { precision: precision }),
      currencyExchangeRate.currency_symbol
    )
  })
}

/**
 * User to split camel case and set first letter to upper case
 * example: helloWorld -> Hello World
 * @param str 
 * @returns 
 */
export function splitAndCapitalize(str: string): string {
  // Split the string at each capital letter
  const words = str.split(/(?=[A-Z])/);

  // Join the words with a space
  let result = words.join(' ');

  // Capitalize the first letter if it's not already capitalized
  if (result.charAt(0) !== result.charAt(0).toUpperCase()) {
    result = result.charAt(0).toUpperCase() + result.slice(1);
  }

  return result;
}
