import React from 'react'
import currencyJS from 'currency.js'

interface Props {
  symbol?: string
  code?: string
  currencyCb: currency
  unit_of_measurement_price_label?: string
  autoDetectSymbol?: boolean
}

/**
 * Default options for formatting currency
 */
const defaultOptions = {
  separator: ',',
  decimal: '.',
}

const currencySymbols = {
  USD: '$',
  EUR: '€',
  CAD: '$',
}

const defaultSymbol = currencySymbols.USD

/**
 * Use as callback, with add(), multiply(), etc.
 */
export function currencyCb(value: number | string, options?): currency {
  const setOptions = { ...defaultOptions, ...options }
  return currencyJS(value, setOptions)
}

/**
 * Use when you absolutely need to render a string.
 */
export function currencyString(
  currencyCb: currency,
  symbol?: string,
  code?: string,
  unit_of_measurement_price_label?: string,
  autoDetectSymbol?: boolean
): string {
  const adjustedSymbol =
    autoDetectSymbol && symbol === undefined && code !== undefined
      ? currencySymbols[code] || defaultSymbol
      : symbol || ''

  return `${adjustedSymbol}${currencyCb.format()}${code ? ` ${code}` : ''}${
    unit_of_measurement_price_label || ''
  }`
}

export const moneyString = (
  value: string | number = 0,
  currency = 'USD',
  precision = 2,
  locale = 'en-US'
): string => {
  let amount = 0

  if (typeof value === 'number') {
    amount = value
  } else if (typeof value === 'string') {
    amount = parseFloat(value.replace(',', '')) || 0 // to get rid of -0
  }

  return amount.toLocaleString(locale, {
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: 'currency',
  })
}

export function currencyDivide(value: number | string, exchangeRate: number, options?): currency {
  const setOptions = { ...defaultOptions, ...options }
  return currencyJS(value, setOptions).divide(exchangeRate)
}

export function currencyMultiply(value: number | string, exchangeRate: number, options?): currency {
  const setOptions = { ...defaultOptions, ...options }
  return currencyJS(value, setOptions).multiply(exchangeRate)
}

/**
 * Preferable to use as React node in case
 * we'll want to add markup and styles to symbols and codes.
 */
const Currency: React.FunctionComponent<Props> = ({
  symbol,
  code,
  currencyCb,
  unit_of_measurement_price_label,
  autoDetectSymbol,
}) => {
  const displayString = currencyString(
    currencyCb,
    symbol,
    code,
    unit_of_measurement_price_label,
    autoDetectSymbol
  )
  return <>{displayString}</>
}

export default Currency
